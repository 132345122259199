import React, { useState, useContext } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Delete, Edit } from '@material-ui/icons'
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography
} from '@material-ui/core'
import hospitalContext from '../../context/hospitals/hospitalContext'
import { ContextProps, BedType } from '../../types'
import firebase from '../../firebase'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '95%',
    maxWidth: theme.spacing(90)
  },
  label: {
    flexBasis: theme.spacing(15),
    marginTop: theme.spacing(2)
  },
  legend: {
    margin: theme.spacing(2),
    marginBottom: '0',
    padding: theme.spacing(2),
    paddingBottom: '0',
    fontWeight: 'bold'
  },
  list: {
    overflow: 'auto',
    width: '100%',
    maxHeight: 700,
    maxWidth: 800
  }
}))

const INITIAL_STATE: BedType = {
  _id: '',
  name: '',
  acronym: ''
}

const BedTypesForm = () => {
  const classes = useStyles()
  const [handleSuccess, setHandleSuccess] = useState(false)
  const [handleEditSuccess, setHandleEditSuccess] = useState(false)
  const [handleRemove, setHandleRemove] = useState(false)
  const [editFormat, setEditFormat] = useState(false)
  const [editBedTypeId, setEditBedTypeId] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [deleteName, setDeleteName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [acronymError, setAcronymError] = useState(false)
  const [formState, setFormState] = useState(INITIAL_STATE)

  const { name, acronym } = formState
  const { globalBedTypes, deleteBedType } = useContext(hospitalContext) as ContextProps

  const handleAddBedType = () => {
    if (editFormat) {
      const otherBedTypes = globalBedTypes.filter(b => b._id !== editBedTypeId)
      console.log(otherBedTypes)
      setAcronymError(!(acronym.length === 2 || acronym.length === 3) || (otherBedTypes.find(h => h.acronym === acronym) !== undefined))
      setNameError(name.length < 2 || (otherBedTypes.find(h => h.name === name) !== undefined))
      if ((!(name.length < 2) && (otherBedTypes.find(h => h.name === name) === undefined)) &&
    ((acronym.length === 2 || acronym.length === 3) && (otherBedTypes.find(h => h.acronym === acronym) === undefined))) {
        setFormState({ ...formState, name, acronym })
        editBedType(editBedTypeId)
        setHandleEditSuccess(true)
        setFormState(INITIAL_STATE)
      }
    } else {
      setAcronymError(!(acronym.length === 2 || acronym.length === 3) || (globalBedTypes.find(h => h.acronym === acronym) !== undefined))
      setNameError(name.length < 2 || (globalBedTypes.find(h => h.name === name) !== undefined))
      if ((!(name.length < 2) && (globalBedTypes.find(h => h.name === name) === undefined)) &&
    ((acronym.length === 2 || acronym.length === 3) && (globalBedTypes.find(h => h.acronym === acronym) === undefined))) {
        setFormState({ ...formState, name, acronym })
        createBedType()
        setHandleSuccess(true)
        setFormState(INITIAL_STATE)
      }
    }
  }

  const onClickDeleteBedType = (bedTypeId: string, bedTypeName: string) => {
    setDeleteId(bedTypeId)
    setDeleteName(bedTypeName)
    setHandleRemove(true)
  }
  const removeBedType = (bedTypeId: string) => {
    deleteBedType(bedTypeId)
    setHandleRemove(false)
  }

  async function createBedType () {
    const bedType: BedType = {
      _id: `${Date.now()}-${String(Math.random()).slice(2, 6)}`,
      name,
      acronym
    }
    await firebase.db.collection('bedTypes').doc(bedType._id).set(bedType)
  }

  async function editBedType (bedTypeId: string) {
    setEditFormat(false)
    await firebase.db.collection('bedTypes').doc(bedTypeId).update({ name, acronym })
  }

  async function onClickEditBedType (bedTypeId: string, editName: string, editAcronym:string) {
    setEditBedTypeId(bedTypeId)
    setFormState({ _id: bedTypeId, name: editName, acronym: editAcronym })
    setEditFormat(true)
  }

  console.log(globalBedTypes)

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h1">Global bed types</Typography>
        <form
          onSubmit={e => {
            e.preventDefault()
            handleAddBedType()
          }}
          noValidate
        >
          <fieldset>
            <legend className={classes.legend}>Bed Types</legend>
            <div style={{ marginLeft: '2rem' }}>
              <Grid container spacing={3} style={{ alignItems: 'flex-end' }}>
                <Grid item xs={6} style={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    error={nameError && (name.length < 2 || (globalBedTypes.find(h => h.name === name) !== undefined))}
                    helperText={ (nameError && name.length < 2) ? 'Empty field!'
                      : (((nameError && globalBedTypes.find(h => h.name === name) !== undefined))
                        ? `The bed type "${name}" already exists` : '')}
                    label='Name'
                    name="name"
                    value={name}
                    onChange={e => setFormState({ ...formState, name: e.target.value })}
                  />
                </Grid>
                <Grid item xs={4} style={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    error={acronymError && (!(acronym.length === 2 || acronym.length === 3) || (globalBedTypes.find(h => h.acronym === acronym) !== undefined))}
                    helperText={ acronymError && !(acronym.length === 2 || acronym.length === 3) ? 'It must have 2 or 3 letters'
                      : (((acronymError && globalBedTypes.find(h => h.acronym === acronym) !== undefined))
                        ? `The acronym "${acronym}" already exists` : '')}
                    label='Acronym'
                    name="acronym"
                    value={acronym}
                    onChange={e => setFormState({ ...formState, acronym: e.target.value })}
                  />
                </Grid>
                <Grid item xs={2} >
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth={true}
                  >{editFormat ? 'Save Changes' : 'Add'}
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: '2rem' }}>
              <Grid container spacing={3} style={{ alignItems: 'flex-end' }}>
                <List dense={true} className={classes.list}>
                  {!globalBedTypes ? null : globalBedTypes.map(bedType => (
                    <ListItem key={bedType._id}>
                      <Grid item xs={6}>
                        <ListItemText>
                          {bedType.name}
                        </ListItemText>
                      </Grid>
                      <Grid item xs={4} style={{ flexGrow: 1 }}>
                        <ListItemText style={{ marginLeft: '1rem' }}>
                          {bedType.acronym}
                        </ListItemText>
                      </Grid>
                      <Grid item xs={2}>
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="edit">
                            <Edit
                              fontSize="small"
                              onClick={() => onClickEditBedType(bedType._id, bedType.name, bedType.acronym)}
                            />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            style={{ marginRight: '1rem' }}
                            onClick={() => onClickDeleteBedType(bedType._id, bedType.name)}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </div>
          </fieldset>
        </form>
        <Dialog
          open={handleRemove}
          onClose={() => setHandleRemove(false)}
        >
          <DialogTitle>Are you sure you want to delete?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span style={{ fontWeight: 600 }}>{deleteName}</span> will be permanently removed. This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHandleRemove(false)} color="primary">
              No, cancel
            </Button>
            <Button onClick={() => removeBedType(deleteId)} color="primary">
              Yes, delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={handleSuccess}
          onClose={() => setHandleSuccess(false)}
        >
          <DialogTitle>Bed type created!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The new bed type has been successfully added
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHandleSuccess(false)} color="primary">
              ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={handleEditSuccess}
          onClose={() => setHandleEditSuccess(false)}
        >
          <DialogTitle>Changes saved!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The changes of the bed type has been successfully saved
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHandleEditSuccess(false)} color="primary">
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}

export default BedTypesForm
