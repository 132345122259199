import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import firebaseConfig from './config'

class Firebase {
  auth: firebase.auth.Auth
  db: firebase.firestore.Firestore
  // Regist and User
  async registrar (name: string, email: string, password: string) {
    const newUser = await this.auth.createUserWithEmailAndPassword(email, password)

    return await newUser.user?.updateProfile({
      displayName: name
    })
  }

  constructor () {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
    }
    this.auth = firebase.auth()
    this.db = firebase.firestore()
  }
}

const firebaseApp = new Firebase()
export default firebaseApp
