import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  TextField,
  useTheme
} from '@material-ui/core'
import { UserLogin, ErrorLogin } from './Authenticator'
import amalfiLogo from '../../assets/AMALFI_LOGO.png'
import logo from '../../assets/mintLogoTemp_alpha.png'

const useStyles = makeStyles((theme) => ({
  userform: {
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
    margin: theme.spacing(8),
    maxWidth: 500,
    width: '95%',
    borderRadius: theme.spacing(2)
  },
  formField: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    alignItems: 'center'
  },
  a: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  label: {
    flexBasis: theme.spacing(13)
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1)
  },
  logoMint: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3)
  }
}))

interface ILogInProps {
  onLogin: (user: UserLogin) => void
  forgotUrl: string
  error: ErrorLogin
  loginngIn: boolean
}

const Login = ({ onLogin, forgotUrl, loginngIn, error }: ILogInProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const [user, saveUser] = useState<UserLogin>({
    email: '',
    password: ''
  })
  const { email, password } = user
  const onChangeLogIn = (e: { target: { name: string; value: string } }) => {
    saveUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }
  // when user want to Log In
  const onSubmit = (e: { preventDefault: () => void }) => {
    onLogin(user)
    e.preventDefault()
  }

  return (
    <div className={classes.userform}>
      <div className={classes.container}>
        <div className={classes.logo}><img src={amalfiLogo} height="100" alt="amalfiLogo" /></div>
        <div className={classes.logoMint}><Typography style={{ paddingTop: '1.1rem', paddingRight: '1rem' }} variant="h4">ADMIN -</Typography><img src={logo} height="50" alt="mintLogo" /></div>

        <form
          onSubmit={onSubmit}
        >
          <div className={classes.formField}>
            <label className={classes.label} htmlFor="email">Email</label>
            <TextField
              required
              variant="outlined"
              fullWidth
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              value={email}
              onChange={onChangeLogIn}
            />
          </div>
          <div className={classes.formField}>
            <label className={classes.label} htmlFor="password">Password</label>
            <TextField
              required
              variant="outlined"
              fullWidth
              type="password"
              id="password"
              name="password"
              placeholder="Your Password"
              value={password}
              onChange={onChangeLogIn}
            />
          </div>
          <div style={{ textAlign: 'right' }}>
            <a className={classes.a} href={forgotUrl}>Forgot password?</a>
          </div>
          <br />
          <span>{loginngIn && 'Loging in...'}</span>
          <span style={{ color: theme.palette.error.light }} >{error && `Error: ${error.message}`}</span>
          <div style={{ textAlign: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >Log In</Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
