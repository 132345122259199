import React from 'react'
import { DatePicker } from '@material-ui/pickers'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import isSameDay from 'date-fns/isSameDay'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import isWithinInterval from 'date-fns/isWithinInterval'
import clsx from 'clsx'
import { ca } from 'date-fns/locale'

const useStyles = makeStyles(theme => ({
  dayWrapper: {
    position: 'relative'
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit'
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%'
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled
  },
  highlightNonCurrentMonthDay: {
    color: '#676767'
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  },
  inputElement: {
    minWidth: 300
  }
}))

type ClassesType = Record<'dayWrapper' | 'day' | 'customDayHighlight' | 'nonCurrentMonthDay' | 'highlightNonCurrentMonthDay' | 'highlight' | 'firstHighlight' | 'endHighlight', string>

const renderWrapped = (classes: ClassesType, period: string) => (date: Date | null, selectedDate: Date | null, dayInCurrentMonth: boolean) => {
  if (date === null) {
    date = new Date()
  }
  if (selectedDate === null) {
    selectedDate = new Date()
  }

  const dateClone = new Date(date.getTime())
  const selectedDateClone = new Date(selectedDate.getTime())

  const start = period === 'week' ? startOfWeek(selectedDateClone, { weekStartsOn: 1 }) : selectedDateClone
  const end = period === 'week' ? endOfWeek(selectedDateClone, { weekStartsOn: 1 }) : selectedDateClone

  const dayIsBetween = isWithinInterval(dateClone, { start, end })
  const isFirstDay = isSameDay(dateClone, start)
  const isLastDay = isSameDay(dateClone, end)

  const wrapperClassName = clsx({
    [classes.highlight]: dayIsBetween,
    [classes.firstHighlight]: isFirstDay,
    [classes.endHighlight]: isLastDay
  })

  const dayClassName = clsx(classes.day, {
    [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
    [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
  })

  const inFuture = date > new Date()

  return (
    <div className={wrapperClassName}>
      <IconButton className={dayClassName} disabled={!dayInCurrentMonth || inFuture}>
        <span> {format(dateClone, 'd')} </span>
      </IconButton>
    </div>
  )
}

interface Props {
    period: string
    selectedDate: Date
    onChange: (date: Date | null) => void
}

const formatWeekSelectLabel = (period: string) => (date: Date | null, invalidLabel: string) => {
  if (date === null) {
    return invalidLabel
  }
  const dateClone = new Date(date.getTime())

  if (period === 'week') {
    return dateClone && isValid(dateClone)
      ? `Setmana del ${format(startOfWeek(dateClone, { weekStartsOn: 1 }), "d 'de' MMMM 'de' yyyy", { locale: ca })}`
      : invalidLabel
  } else if (period === 'month') {
    return dateClone && isValid(dateClone)
      ? `${format(dateClone, "MMMM 'de' yyyy", { locale: ca })}`
      : invalidLabel
  } else if (period === 'total') {
    return 'Tot el període fins avui inclòs.'
  }

  return dateClone && isValid(dateClone)
    ? `${format(dateClone, " d 'de' MMMM 'de' yyyy", { locale: ca })}`
    : invalidLabel
}

const DateSelector = ({ period, onChange, selectedDate }: Props) => {
  const classes = useStyles()
  return (
    <DatePicker
      className={classes.inputElement}
      disabled={period === 'total'}
      views={period === 'month' ? ['month'] : ['date']}
      variant="inline"
      label="Data"
      value={selectedDate}
      renderDay={renderWrapped(classes, period)}
      onChange={onChange}
      labelFunc={formatWeekSelectLabel(period)}
      maxDate={new Date()}
    />
  )
}

export default DateSelector
