import React, { useContext } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import IconButton from '@material-ui/core/IconButton'
import HomeIcon from '@material-ui/icons/Home'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import { AppBar, Toolbar } from '@material-ui/core'
import { Link } from 'react-router-dom'
import hospitalContext from '../../context/hospitals/hospitalContext'
import { ContextProps } from '../../types'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  }
}))

const MainAppBar: React.SFC<{}> = () => {
  const classes = useStyles()
  const { logoutAction } = useContext(hospitalContext) as ContextProps

  return (
    <AppBar position="static">
      <Toolbar>
        <h1>ADMIN<span style={{ fontWeight: 400 }}>Tasks</span></h1>
        {/* <p className="nombre-usuario">Hello <span>Idoia Beraza</span></p> */}
        <div className={classes.grow} />
        <IconButton
          color="inherit"
          title={'home'}
          component={Link}
          to="/"
        >
          <HomeIcon />
        </IconButton>
        <IconButton
          color="inherit"
          title={'Sign off'}
          component={Link}
          to="/"
          onClick={logoutAction}
        >
          <PowerSettingsNewIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default MainAppBar
