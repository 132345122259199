import React, { useContext, useState, useEffect } from 'react'
import Cookies from 'universal-cookie'
import firebase from 'firebase'
import axios from 'axios'
import hospitalContext from '../../context/hospitals/hospitalContext'
import Login from './Login'
import { ContextProps, AuthData } from '../../types'
import firebaseConfig from '../../firebase/config'
import { startRefreshLoop } from './refreshLoop'

export interface UserLogin {
  email: string
  password: string
}

export type ErrorLogin = {
  message: string
} | null

interface Props {
  children: React.ReactNode
}

const cookies = new Cookies()
// const functionsUrl = 'http://localhost:3002'

const loginUrl = firebaseConfig.loginUrl
const FORGOT_URL = `https://auth.amalfianalytics.com/password/forgot?tenantId=${firebaseConfig.amalfiTenant}`

// FIXME that's dirty.
startRefreshLoop()

const checkAdmin = async (loginData: AuthData) => {
  const { registrations } = loginData.user

  const registration = registrations.find(r => r.applicationId === firebaseConfig.amalfiApplicationId)
  if (!registration) {
    throw new Error('User is not registered')
  }
  if (!registration.roles) {
    throw new Error('This user has not enough permissions')
  }
  const isAdmin = !!registration.roles.find(r => r === 'Admin')
  if (!isAdmin) {
    throw new Error('This user has not enough permissions')
  }
  return loginData
}

// Authentication logic
const checkAuth = (
  authenticated: boolean,
  setLoginError: React.Dispatch<React.SetStateAction<ErrorLogin>>,
  authenticateAction: (authData: AuthData) => void,
  setLogingIn: React.Dispatch<React.SetStateAction<boolean>>
) => () => {
  if (authenticated) {
    return
  }
  const loginCookie = cookies.get('login') as AuthData
  if (loginCookie) {
    checkAdmin(loginCookie).then(() => handleSignin(loginCookie, setLoginError, authenticateAction, setLogingIn))
  }
}

// Get authToken
const handleSignin = (
  loginData: AuthData,
  setLoginError: React.Dispatch<React.SetStateAction<ErrorLogin>>,
  authenticateAction: (authData: AuthData) => void,
  setLogingIn: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { token } = loginData
  setLogingIn(true)
  setLoginError(null)
  return Promise.all([loginData, firebase.auth().signInWithCustomToken(token)])
    .then(([loginData]) => {
      cookies.set('login', loginData, { path: '/' })
      setLogingIn(false)
      authenticateAction(loginData)
    })
    .catch(error => {
      console.error('err', error)
      setLoginError({ message: 'Session expired' })
      setLogingIn(false)
    })
}

const handleLogin = (
  { email, password }: UserLogin,
  setLoginError: React.Dispatch<React.SetStateAction<ErrorLogin>>,
  authenticateAction: (authData: AuthData) => void,
  setLogingIn: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const data = {
    loginId: email,
    password,
    applicationId: firebaseConfig.amalfiApplicationId
  }
  const headers = {
    Authorization: firebaseConfig.amalfiLoginKey
  }
  setLogingIn(true)
  setLoginError(null)
  axios.post(loginUrl, data, { headers })
    .then(resp => checkAdmin(resp.data))
    .then(data => {
      const newData = {
        token: data.token,
        refreshToken: data.refreshToken,
        user: {
          registrations: data.user.registrations.filter(r => r.applicationId === firebaseConfig.amalfiApplicationId),
          email: data.user.email
        }
      }
      return handleSignin(newData, setLoginError, authenticateAction, setLogingIn)
    })
    .catch((e) => {
      console.log(e.message)
      if (e.message === 'This user has not enough permissions') {
        setLoginError({ message: e.message })
      } else {
        setLoginError({ message: 'Invalid credentials' })
      }
      setLogingIn(false)
    })
}

const Authenticator = ({ children }: Props) => {
  const { authenticated, authenticateAction } = useContext(hospitalContext) as ContextProps
  const [loginError, setLoginError] = useState<ErrorLogin>(null)
  const [loginngIn, setLogingIn] = useState(false)
  useEffect(checkAuth(authenticated, setLoginError, authenticateAction, setLogingIn), [authenticated])
  if (!authenticated) {
    return (
      <Login
        onLogin={user => handleLogin(user, setLoginError, authenticateAction, setLogingIn)}
        error={loginError}
        forgotUrl={FORGOT_URL}
        loginngIn={loginngIn}
      />
    )
  }
  return (<div>{children}</div>)
}

export default Authenticator
