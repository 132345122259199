export const HOSPITALS_LIST = 'HOSPITALS_LIST'
export const GET_HOSPITALS = 'GET_HOSPITALS'
export const GET_BED_TYPES = 'GET_BED_TYPES'
export const ADD_HOSPITAL = 'ADD_HOSPITAL'
export const DELETE_HOSPITAL = 'DELETE_HOSPITAL'
export const AUTH_USER = 'AUTH_USER'
export const LOGOUT = 'LOGOUT'
// export const BED_TYPES = ['longstay', 'convalescent', 'paliative', 'subacute', 'mental_health']

export interface PartialHospital {
  name: string
}

export interface HospitalTarget {
  _id: string
  name: string
  bedTypes: string[]
}

export interface HospitalSource {
  _id: string
  name: string
}

export interface BedType {
  _id: string
  name: string
  acronym: string
}

export interface RequesterHospital extends PartialHospital {
  _id: string
  fullName: string
  type: string
  users: string[]
  target: HospitalTarget[]
}

export interface OffererHospital extends PartialHospital {
  _id: string
  fullName: string
  type: string
  users: string[]
  bedTypes: string[]
  source: HospitalSource[]
}

export type Hospital = RequesterHospital | OffererHospital

export interface AuthUser {
  email: string
  registrations: {
    applicationId: string
    roles: string[]
  } []
}

export interface AuthData {
  token: string,
  refreshToken: string
  user: AuthUser
}

export interface ContextActions {
  getHospitals: (hospitals: Hospital[]) => void
  getBedTypes: (globalBedTypes: BedType[]) => void
  deleteHospital: (hospitalId: string) => void
  deleteBedType: (bedTypeId: string) => void
  authenticateAction: (authData: AuthData) => void
  logoutAction: () => void
}

export interface ContextAttributes {
  authenticated: boolean
  lang: string
  hospitals: Hospital[]
  globalBedTypes: BedType[]
}

export interface ContextProps extends ContextActions, ContextAttributes {}
