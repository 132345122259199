import React, { useState } from 'react'
import { useAsync } from 'react-use'
import axios from 'axios'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import format from 'date-fns/format'
import { get, map } from 'lodash'
import DashboardsAppBar from '../layout/DashboardsAppBar'
import { HOSTPITAL_TO_NAME, CURRENT_OFFERS_INDEX } from '../constants/constants'
import {
  buildCancelledRequest, buildPendingRequest,
  buildAcceptedRejectedRequest, buildCreatedOffer, buildCancelledOffer,
  buildAcceptedRejectedOffer, buildPendingOffer,
  buildAllRequests, buildFinishedOffersRequests, buildPendingOffer1d
} from '../../lib/esAggregations'
import DataTable from './DataTable'
import PeriodSelection from './PeriodSelection'
import DateSelector from './DateSelector'

const URL = '/elastic/'

const parseESAggregation = (data: any) => {
  const hospitalBuckets = data.aggregations.by_hospital.buckets
  const matrix = hospitalBuckets.map((hospital: any) => ({
    key: hospital.key,
    value: hospital.by_type.buckets.map((type: any) => ({
      key: type.key,
      value: type.num_beds
    }))
  }))
  return matrix
}

const parseSQLAggregation = (data: any) => {
  const preMatrix: any = {}
  data.rows.forEach((row: any[]) => {
    const element: any = get(preMatrix, row[0], {})
    element[row[1]] = row[2]
    preMatrix[row[0]] = element
  })
  const matrix = map(preMatrix, (element, hospital: string) => ({
    key: hospital,
    value: map(element, (val: number, bedType: string) => ({
      key: bedType, value: { value: val }
    }))
  }))
  return matrix
}

const asyncESQuery = (query: any, index: string = CURRENT_OFFERS_INDEX) => async () => {
  const isSqlQuery = typeof query.query === 'string'
  const endpoint = isSqlQuery ? `${URL}_sql?format=json` : `${URL}${index}/_search`
  const response = await axios.post(endpoint, query)
  const matrix = isSqlQuery ? parseSQLAggregation(response.data) : parseESAggregation(response.data)
  return matrix
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    backgroundColor: '#ccc'
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  },
  controlElement: {
    marginLeft: theme.spacing(2)
  }
}))

const getFromDate = (selectedDate: Date, period: string) => {
  if (period === 'total') {
    return '2000-01-01'
  }
  let newDate = selectedDate
  if (period === 'week') {
    newDate = startOfWeek(selectedDate, { weekStartsOn: 1 })
  }
  if (period === 'month') {
    newDate = startOfMonth(selectedDate)
  }
  return format(newDate, 'yyyy-MM-dd')
}

const getToDate = (selectedDate: Date, period: string) => {
  if (period === 'total') {
    return format(new Date(), 'yyyy-MM-dd')
  }
  let newDate = selectedDate
  if (period === 'week') {
    newDate = endOfWeek(selectedDate, { weekStartsOn: 1 })
  }
  if (period === 'month') {
    newDate = endOfMonth(selectedDate)
  }
  return format(newDate, 'yyyy-MM-dd')
}

const HistoricalDashboard = ({ match }: { match: any }) => {
  const [period, setPeriod] = useState('day')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const hospitalId: string = match.params.hospitalId
  const hospitalName = HOSTPITAL_TO_NAME[hospitalId] || ''

  const from = getFromDate(selectedDate, period)
  const to = getToDate(selectedDate, period)

  const changeDates = (date: Date | null) => {
    if (date !== null) {
      setSelectedDate(date)
    }
  }

  // const completedRequests = useAsync(asyncESQuery(buildCompletedRequests(from, to, hospitalName)), [selectedDate, period])
  const cancelledRequest = useAsync(asyncESQuery(buildCancelledRequest(from, to, hospitalId)), [selectedDate, period])
  const acceptedRejectedRequest = useAsync(asyncESQuery(buildAcceptedRejectedRequest(from, to, hospitalId)), [selectedDate, period])
  const pendingRequest = useAsync(asyncESQuery(buildPendingRequest(from, to, hospitalId)), [selectedDate, period])
  const allRequests = useAsync(asyncESQuery(buildAllRequests(from, to, hospitalId)), [selectedDate, period])
  const createdOffer = useAsync(asyncESQuery(buildCreatedOffer(from, to, hospitalId)), [selectedDate, period])
  const cancelledOffer = useAsync(asyncESQuery(buildCancelledOffer(from, to, hospitalId)), [selectedDate, period])
  const acceptedRejectedOffer = useAsync(asyncESQuery(buildAcceptedRejectedOffer(from, to, hospitalId)), [selectedDate, period])
  const pendingOffer = useAsync(asyncESQuery(buildPendingOffer1d(from, to, hospitalId)), [selectedDate, period])
  const availableOffer = useAsync(asyncESQuery(buildPendingOffer(from, to, hospitalId)), [selectedDate, period])
  // const completedOffer = useAsync(asyncESQuery(buildCompletedOffer(from, to, hospitalName)), [selectedDate, period])
  const finishedOffersRequests = useAsync(asyncESQuery(buildFinishedOffersRequests(from, to, hospitalId)), [selectedDate, period])

  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div>
        <DashboardsAppBar title={`HISTÒRIC - ${hospitalName}`} />
      </div>
      <Paper className={classes.controls}>
        <PeriodSelection value={period} onChange={setPeriod} />
        <div className={classes.controlElement}>
          <DateSelector period={period} onChange={changeDates} selectedDate={selectedDate} />
        </div>
      </Paper>
      <Grid container>
        {/* <DataTable title="Sol·licituds Completades" data={completedRequests} hospitalId={hospitalId} helper="Sol·licituds completades (finalitzades)." />
        <DataTable title="Ofertes Completades" data={completedOffer} hospitalId={hospitalId} helper="Ofertes que han estat completades durant el període." /> */}

        <DataTable title="Sol·licituds Pendents" data={pendingRequest} hospitalId={hospitalId} helper="Sol·licituds no acceptades al final del període." />
        <DataTable title="Ofertes Pendents >1d" data={pendingOffer} hospitalId={hospitalId} helper="Ofertes que no s'han acceptat al final del període i han estat disponibles un dia o més." />

        <DataTable title="Ofertes Disponibles" data={availableOffer} hospitalId={hospitalId} helper="Ofertes que han quedat disponibles al final del període." />
        <DataTable title="Ofertes Creades" data={createdOffer} hospitalId={hospitalId} helper="Ofertes creades durant el període (no inclou cancel·lades)" />
        <DataTable title="Ofertes Cancel·lades (abans d'acceptar)" data={cancelledOffer} hospitalId={hospitalId} helper="Ofertes cancel·lades sense haver estat acceptades." />
        <DataTable title="Ofertes Cancel·lades (després d'acceptar)" data={acceptedRejectedOffer} hospitalId={hospitalId} helper="Ofertes cancel·lades quan ja havien estat acceptades. Poden tornar-se acceptar després." />

        <DataTable title="Sol·licituds (totes)" data={allRequests} hospitalId={hospitalId} helper="Totes les sol·licituds incloent cancel·lades creades durant el període." />
        <DataTable title="Sol·licituds Cancel·lades (abans d'acceptar)" data={cancelledRequest} hospitalId={hospitalId} helper="Sol·licituds cancel·lades sense haver estat acceptades." />
        <DataTable title="Sol·licituds Cancel·lades (després d'acceptar)" data={acceptedRejectedRequest} hospitalId={hospitalId} helper="Sol·licituds cancel·lades quan ja havien estat acceptades." />
        <DataTable title="Pacients amb procés finalitzat" data={finishedOffersRequests} hospitalId={hospitalId} helper="Pacients que han estat donats d'alta durant el període." />

      </Grid>
    </div>
  )
}

export default HistoricalDashboard
