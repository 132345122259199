import { createContext } from 'react'

// interface AppContextInterface {
//     value?: any
//   }

// const FirebaseContext = createContext<AppContextInterface|null>(null)
const FirebaseContext = createContext(null)

export default FirebaseContext
