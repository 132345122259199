export const config = {
  apiKey: 'AIzaSyBYfjTckWVAjQ2ZjdFHpVpnwIJOjLeLe-Q',
  authDomain: 'mint-3778f.firebaseapp.com',
  databaseURL: 'https://mint-3778f.firebaseio.com',
  projectId: 'mint-3778f',
  storageBucket: 'mint-3778f.appspot.com',
  messagingSenderId: '615306828558',
  appId: '1:615306828558:web:5ffb58a299200b4a',
  region: 'europe-west1',
  amalfiApplicationId: '84a41cf2-9191-422f-b87b-1bf3c59e4406',
  amalfiTenant: 'c9520924-d349-4ca7-b5e9-d2956e0812f4',
  amalfiLoginKey: 'qn_swhO7AwxD62lH9vB92PVlwwtj0nt17jGkxjr2LRg',
  loginUrl: 'https://pilot.login.mint.amalfianalytics.com/login',
  refreshUrl: 'https://pilot.login.mint.amalfianalytics.com/refresh'
}

export default config
