import { createMuiTheme } from '@material-ui/core/styles'

// Palette generator:
export const THEME = createMuiTheme({
  palette: {
    background: {
      default: '#ffffff'
    },
    primary: {
      main: '#424c5e',
      dark: '#798499',
      light: '#edf2f6'
    }
    // secondary: {
    //   main: '#edf2f6'
    // }
  },
  typography: {
    // fontFamily: 'Roboto',
    h1: {
      fontWeight: 900,
      fontSize: '2rem',
      textAlign: 'center',
      marginBottom: '2rem',
      color: '#424c5e'
    }
  }
})
