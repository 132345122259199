import { CURRENT_OFFERS_INDEX } from '../components/constants/constants'


// Requests Completed between fromDate and toDate
export const buildCompletedRequests = (fromDate: string, toDate: string, hospitalName: string) => ({
    "size": 0,
    "query": {
        "bool": {
            "must": [
                {
                    "term": {
                        "type.keyword": "REQUEST"
                    }
                },
                {
                    "term": {
                        "destination.keyword": hospitalName
                    }
                },
                {
                    "range": {
                        "completedDate": {
                            "gte": fromDate,
                            "lte": toDate
                        }
                    }
                }
            ]
        }
    },
    "aggs": {
        "by_hospital": {
            "terms": {
                "field": "origin.keyword"
            },
            "aggs": {
                "by_type": {
                    "terms": {
                        "field": "bedType.keyword"
                    },
                    "aggs": {
                        "num_beds": {
                            "sum": {
                                "field": "numBeds"
                            }
                        }
                    }
                }
            }
        }
    }
})


// SOL.LICITUDS CANCEL.LADES (abans d'acceptar). Totes les cancel.lacions, sense cap distició fins tenir motiu
export const buildCancelledRequest = (fromDate: string, toDate: string, hospitalId: string) => ({
    "query": `
        SELECT involvedHospitals.keyword as hospitals, bedType, SUM(numBeds) as numBeds
        FROM "${CURRENT_OFFERS_INDEX}"
        WHERE type = 'REQUEST'
            AND state.keyword = 'CANCELED'
            AND involvedHospitals.keyword = '${hospitalId}'
            AND lastUpdate BETWEEN '${fromDate}' AND '${toDate}'
        GROUP BY hospitals, bedType
    `
})

// Requests accepted and then canceled between fromDate and toDate
export const buildAcceptedRejectedRequest = (fromDate: string, toDate: string, hospitalId: string) => ({
    "size": 0,
    "query": {
        "bool": {
            "must": [
                {
                    "term": {
                        "type.keyword": "REQUEST"
                    }
                },
                {
                    "term": {
                        "state.keyword": "ACCEPTED_REJECTED"
                    }
                },
                {
                    "term": {
                        "involvedHospitals.keyword": hospitalId
                    }
                },
                {
                    "range": {
                        "lastUpdate": {
                            "gte": fromDate,
                            "lte": toDate
                        }
                    }
                }
            ]
        }
    },
    "aggs": {
        "by_hospital": {
            "terms": {
                "field": "involvedHospitals.keyword"
            },
            "aggs": {
                "by_type": {
                    "terms": {
                        "field": "bedType.keyword"
                    },
                    "aggs": {
                        "num_beds": {
                            "sum": {
                                "field": "numBeds"
                            }
                        }
                    }
                }
            }
        }
    }
})

// OFERTES PENDENTS AL FINAL DEL DIA, O SIGUI A LES 24:00
// comptar OFERTES PENDENTS DEL DIA: son tots els llits disponibles per
// AVUI que no s'han ocupat. No compta quan siguin creats, només que siguin pel dia.
// Queden exclosos els de dies següents.
export const buildPendingRequest = (fromDate: string, toDate: string, hospitalId: string) => ({
    "query": `
    SELECT involvedHospitals.keyword as hospitals, bedType, SUM(numBeds) as numBeds
    FROM "${CURRENT_OFFERS_INDEX}"
    WHERE type = 'REQUEST'
        AND (
            (acceptDate IS NULL AND (
                state.keyword <> 'CANCELED' OR
                    (
                        state.keyword = 'CANCELED' AND  lastUpdate > '${toDate}'
                    )
                )
            )
            OR acceptDate > '${toDate}'
        )
        AND involvedHospitals.keyword = '${hospitalId}'
        AND date <= '${toDate}'
    GROUP BY hospitals, bedType
`
})

// SOL.LICITUDS TOTALS, son totes les sol.licituds realitzades tant pel dia, com per dies següents.
// OJO QUE AQUÍ NO QUEDEN EXCLOSES LES CANCEL.LADES JA QUE VOLEN ESPECIFICAMENT QUE LA XIFRA SIGUI
// TOT LO QUE HAN DEMANAT.
export const buildAllRequests = (fromDate: string, toDate: string, hospitalId: string) => ({
    "query": `
        SELECT involvedHospitals.keyword as hospitals, bedType, SUM(numBeds) as numBeds
        FROM "${CURRENT_OFFERS_INDEX}"
        WHERE type = 'REQUEST'
            AND involvedHospitals.keyword = '${hospitalId}'
            AND creationDate BETWEEN '${fromDate}' AND '${toDate}'
        GROUP BY hospitals, bedType
    `
})

// OFERTES CREADES: son TOTES les generades entre les 00:00 i les 24:00 del dia. CANCEL.LADES
export const buildCreatedOffer = (fromDate: string, toDate: string, hospitalId: string) => ({
    "query": `
        SELECT origin.keyword as hospitals, bedType, SUM(numBeds) as numBeds
        FROM "${CURRENT_OFFERS_INDEX}"
        WHERE type = 'OFFER'
            AND state.keyword <> 'CANCELED'
            AND targetHospitals.keyword = '${hospitalId}'
            AND creationDate BETWEEN '${fromDate}' AND '${toDate}'
            AND duplicatedOf.keyword IS NULL
        GROUP BY hospitals, bedType
    `
})

// OFERTES CANCEL.LADES, son TOTES les cancel.lades al dia abans d'acceptar  ( ,oltes es cancel.len per duplicitat)
export const buildCancelledOffer = (fromDate: string, toDate: string, hospitalId: string) => ({
    "query": `
        SELECT origin.keyword as hospitals, bedType, SUM(numBeds) as numBeds
        FROM "${CURRENT_OFFERS_INDEX}"
        WHERE type = 'OFFER'
            AND state.keyword = 'CANCELED'
            AND targetHospitals.keyword = '${hospitalId}'
            AND lastUpdate BETWEEN '${fromDate}' AND '${toDate}'
        GROUP BY hospitals, bedType
    `
})

// OFERTES CANCEL.LADES, son TOTES les cancel.lades al dia després d'acceptar
export const buildAcceptedRejectedOffer = (fromDate: string, toDate: string, hospitalId: string) => ({
    "query": `
    SELECT origin.keyword as hospitals, bedType, SUM(numBeds) as numBeds
    FROM "${CURRENT_OFFERS_INDEX}"
    WHERE type = 'OFFER'
        AND (
            log.message.keyword = 'Acceptació de oferta CANCEL·LADA'
            OR duplicatedOf.keyword IS NOT NULL
            )
        AND targetHospitals.keyword = '${hospitalId}'
        AND lastUpdate BETWEEN '${fromDate}' AND '${toDate}'
    GROUP BY hospitals, bedType
`
})

//OFERTES PENDENTS AL FINAL DEL DIA, O SIGUI A LES 24:00 comptar OFERTES PENDENTS DEL DIA:
// son tots els llits disponibles per AVUI que no s'han ocupat. No compta quan siguin creats,
// només que siguin pel dia. Queden exclosos els de dies següents.
export const buildPendingOffer = (fromDate: string, toDate: string, hospitalId: string) => ({
    "query": `
    SELECT origin.keyword as hospitals, bedType, SUM(numBeds) as numBeds
    FROM "${CURRENT_OFFERS_INDEX}"
    WHERE type = 'OFFER'
        AND (
            (acceptDate IS NULL AND (
                state.keyword <> 'CANCELED' OR
                    (
                        state.keyword = 'CANCELED' AND  lastUpdate > '${toDate}'
                    )
                )
            )
            OR acceptDate > '${toDate}'
        )
        AND involvedHospitals.keyword = '${hospitalId}'
        AND date <= '${toDate}'
        AND duplicatedOf.keyword IS NULL
    GROUP BY hospitals, bedType
`
})

export const buildPendingOffer1d = (fromDate: string, toDate: string, hospitalId: string) => ({
    "query": `
    SELECT origin.keyword as hospitals, bedType, SUM(numBeds) as numBeds
    FROM "${CURRENT_OFFERS_INDEX}"
    WHERE type = 'OFFER'
        AND (
            (acceptDate IS NULL AND (
                state.keyword <> 'CANCELED' OR
                    (
                        state.keyword = 'CANCELED' AND  lastUpdate > '${toDate}'
                    )
                )
            )
            OR acceptDate > '${toDate}'
        )
        AND involvedHospitals.keyword = '${hospitalId}'
        AND date < '${toDate}'
        AND duplicatedOf.keyword IS NULL
    GROUP BY hospitals, bedType
`
})


// OFERTES DISPONIBLES: Son les que porten data del dia, indistintament de la data de creació. EXCLOU CANCEL.LADES
// Ofertes que estaven disponibles durant el període
export const buildAvailableOffer = (fromDate: string, toDate: string, hospitalId: string) => ({
    "query": `
    SELECT origin.keyword as hospitals, bedType, SUM(numBeds) as numBeds
    FROM "${CURRENT_OFFERS_INDEX}"
    WHERE
    type.keyword = 'OFFER'
    AND state.keyword <> 'CANCELED'
    AND involvedHospitals.keyword = '${hospitalId}'
    AND date BETWEEN '${fromDate}' AND '${toDate}'
    AND duplicatedOf.keyword IS NULL
    GROUP BY hospitals, bedType
`
})

// export const buildAvailableOffer = (fromDate: string, toDate: string, hospitalId: string) => ({
//     "query": `
//     SELECT origin.keyword as hospitals, bedType, SUM(numBeds) as numBeds
//     FROM "${CURRENT_OFFERS_INDEX}"
//     WHERE
//     type.keyword = 'OFFER'
//     AND state.keyword <> 'CANCELED'
//     AND involvedHospitals.keyword = '${hospitalId}'
//     AND (
//             acceptDate IS NULL
//             OR acceptDate <= '${toDate}'
//         )
//     AND date >= '${fromDate}'
//     GROUP BY hospitals, bedType
// `
// })


// Offers Completed between fromDate and toDate
export const buildCompletedOffer = (fromDate: string, toDate: string, hospitalName: string) => ({
    "size": 0,
    "query": {
        "bool": {
            "must": [
                {
                    "term": {
                        "type.keyword": "OFFER"
                    }
                },
                {
                    "term": {
                        "destination.keyword": hospitalName
                    }
                },
                {
                    "range": {
                        "completedDate": {
                            "gte": fromDate,
                            "lte": toDate
                        }
                    }
                }
            ]
        }
    },
    "aggs": {
        "by_hospital": {
            "terms": {
                "field": "origin.keyword"
            },
            "aggs": {
                "by_type": {
                    "terms": {
                        "field": "bedType.keyword"
                    },
                    "aggs": {
                        "num_beds": {
                            "sum": {
                                "field": "numBeds"
                            }
                        }
                    }
                }
            }
        }
    }
})

// PACIENTS AMB PROCÉS FINALITZAT: son tant SOL.LICITUDS FINALITZADES, com ALTA, com pacients assignats
// a OFERTES que ja estan finalitzades o son alta.
// Nota: faig servir la data d'alta com a data
export const buildFinishedOffersRequests =  (fromDate: string, toDate: string, hospitalId: string) => ({
    "query": `
    SELECT origin.keyword as hospitals, bedType, SUM(numBeds) as numBeds
    FROM "${CURRENT_OFFERS_INDEX}"
    WHERE
     (
        (
            type = 'OFFER'
            AND acceptor._id.keyword = '${hospitalId}'
        ) OR (
            type = 'REQUEST'
            AND sourceHospitals = '${hospitalId}'
        )
     )
    AND state.keyword IN ('DISCHARGED', 'COMPLETED')
    AND dischargedDate BETWEEN '${fromDate}' AND '${toDate}'
    GROUP BY hospitals, bedType
`
})
