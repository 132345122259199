import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import hospitalContext from '../../context/hospitals/hospitalContext'
import { ContextProps } from '../../types'

const useStyles = makeStyles((theme) => ({
  hospitalList: {
    overflow: 'auto',
    height: '65vh'
  },
  hospitalListItem: {
    paddingLeft: theme.spacing(4),
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 600
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0)
  }
}))

export interface HospitalListsProps {
}

const HospitalLists: React.SFC<HospitalListsProps> = () => {
  const classes = useStyles()
  const { hospitals } = useContext(hospitalContext) as ContextProps
  const [selectedHospital, setSelectedHospital] = useState('')

  const handleHospitalClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, hospitalId: React.SetStateAction<string>) => {
    setSelectedHospital(hospitalId)
  }

  if (hospitals.length === 0) return <p>There are no hospitals, start by registering one</p>

  return (
    <>
      <div><Typography variant="h1" className={classes.title}>Hospitals</Typography></div>
      <List component="nav" className={classes.hospitalList}>
        {hospitals.map((hospital) => (
          <Link
            style={{ textDecoration: 'none' }}
            key={hospital._id}
            to={`/edit-hospitals/${hospital._id}`}
          >
            <ListItem
              button
              selected={selectedHospital === hospital._id}
              onClick={event => handleHospitalClick(event, hospital._id)}
              key={hospital._id}
              className={classes.hospitalListItem}
            >
              {hospital.name}
            </ListItem>
          </Link>
        ))}
        {hospitals.length === 0 && '-'}
      </List>
    </>
  )
}

export default HospitalLists
