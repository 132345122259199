import React, { useReducer, useEffect } from 'react'
import hospitalContext from './hospitalContext'
import hospitalReducer from './hospitalReducer'
import Cookies from 'universal-cookie'
import firebase from '../../firebase'
import { addHospitalLinks } from '../../lib/utils'
import {
  GET_HOSPITALS,
  GET_BED_TYPES,
  AUTH_USER,
  ContextAttributes,
  Hospital,
  AuthData,
  LOGOUT,
  BedType
} from '../../types'

const cookies = new Cookies()

const HospitalState = (props: { children: React.ReactNode }) => {
  const initialState: ContextAttributes = {
    authenticated: false,
    lang: 'en',
    hospitals: [],
    globalBedTypes: []
  }

  const [state, dispatch] = useReducer(hospitalReducer, initialState)

  useEffect(() => {
    if (!state.authenticated) {
      return
    }
    firebase.db.collection('hospitals').onSnapshot(
      snapshot => {
        const hospitals = snapshot.docs.map(d => d.data() as Hospital)
        getHospitals(hospitals)
      },
      err => {
        console.error(err)
      }
    )
    firebase.db.collection('bedTypes').onSnapshot(
      snapshot => {
        const globalBedTypes = snapshot.docs.map(d => d.data() as BedType)
        getBedTypes(globalBedTypes)
      },
      err => {
        console.error(err)
      }
    )
  }, [state.authenticated])

  const getHospitals = (hospitals: Hospital[]) => {
    dispatch({
      type: GET_HOSPITALS,
      payload: hospitals
    })
  }
  const getBedTypes = (globalBedTypes: BedType[]) => {
    dispatch({
      type: GET_BED_TYPES,
      payload: globalBedTypes
    })
  }
  const deleteBedType = (bedTypeId: string) => {
    // FIXME this should be without side-effects
    const bedType = state.globalBedTypes.find(h => h._id === bedTypeId)
    if (!bedType) {
      return
    }
    // const bedTypeD = { ...bedType, target: [], source: [] }
    // addHospitalLinks(hospitalD, state.hospitals)
    firebase.db.collection('bedTypes').doc(bedTypeId).delete()
  }

  const deleteHospital = (hospitalId: string) => {
    // FIXME this should be without side-effects
    const hospital = state.hospitals.find(h => h._id === hospitalId)
    if (!hospital) {
      return
    }
    const hospitalD = { ...hospital, target: [], source: [] }
    addHospitalLinks(hospitalD, state.hospitals)
    firebase.db.collection('hospitals').doc(hospitalId).delete()
  }

  const authenticateAction = (authData: AuthData) => dispatch({
    type: AUTH_USER,
    authData
  })

  const logoutAction = () => {
    // FIXME this should be without side-effects
    cookies.remove('login')
    dispatch({ type: LOGOUT })
    firebase.auth.signOut()
    window.location.href = '/'
  }

  const actions = {
    getHospitals,
    getBedTypes,
    deleteHospital,
    deleteBedType,
    authenticateAction,
    logoutAction
  }

  return (
  // where de data start:
    <hospitalContext.Provider
      value={{
        ...state, ...actions
      }}
    >
      {props.children}
    </hospitalContext.Provider>
  )
}

export default HospitalState
