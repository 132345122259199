import React, { useContext } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import IconButton from '@material-ui/core/IconButton'
// import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Link } from 'react-router-dom'
import hospitalContext from '../../context/hospitals/hospitalContext'
import { ContextProps } from '../../types'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: '#1a202d'
  }
}))

const HomeAppBar: React.SFC<{}> = () => {
  const classes = useStyles()
  const { logoutAction } = useContext(hospitalContext) as ContextProps

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <h1>ADMINT - <span style={{ fontWeight: 400 }}>INICI</span></h1>
          {/* <p className="nombre-usuario">Hello <span>Idoia Beraza</span></p> */}
          <div className={classes.grow} />
          <IconButton
            color="inherit"
            title={'home'}
            component={Link}
            to="/"
          >
            <HomeIcon />
          </IconButton>
          <IconButton
            color="inherit"
            title={'Sign off'}
            component={Link}
            to="/"
            onClick={logoutAction}
          >
            <PowerSettingsNewIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default HomeAppBar
