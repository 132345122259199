import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Paper, Button } from '@material-ui/core'
import HospitalLists from '../hospitals/HospitalLists'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.light
  },
  mainSection: {
    flex: 1
  },
  aside: {
    flexBasis: 1,
    padding: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  btn: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    fontWeight: 'bold'
  },
  link: {
    textDecoration: 'none'
  }
}))

export interface LeftPanelProps {

}

const LeftPanel: React.FC<LeftPanelProps> = () => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <div className={classes.aside}>
        <Link to={'/dashboards'} className={classes.link}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            className={classes.btn}
          >Dashboard</Button>
        </Link>
        <Link to={'/add-hospital-type'} className={classes.link}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            className={classes.btn}
          >Add New Hospital</Button>
        </Link>
        <Link to={'/add-bed-types'} className={classes.link}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            className={classes.btn}
          >Bed types</Button>
        </Link>
        {/* <Link to={'/edit-hospitals'} className={classes.link}>
          <Button
            className={classes.btn}
          >Edit Hospital</Button>
        </Link> */}
      </div>
      <div>
        <HospitalLists />
      </div>
    </Paper>
  )
}

export default LeftPanel
