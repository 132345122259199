import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import HomeAppBar from '../layout/HomeAppBar'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  mainSection: {
    flex: 1
  },
  userForm: {
    backgroundColor: theme.palette.primary.main,
    height: '80vh',
    width: '100%',
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  titleForm: {
    backgroundColor: theme.palette.primary.main,
    height: '20vh',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  container: {
    padding: theme.spacing(9),
    margin: theme.spacing(9),
    width: '95%',
    height: '10%',
    backgroundColor: theme.palette.background.default,
    borderRadius: '1rem',
    maxWidth: 300,
    alignItems: 'center'
  },
  linkButtons: {
    display: 'block',
    textDecoration: 'none',
    textAlign: 'center',
    color: theme.palette.primary.main,
    opacity: '.8'
  },
  h2: {
    color: '#edf2f6'
  }
}))

const HospitalType: React.SFC<IHospitalTypeProps> = () => {
  const classes = useStyles()
  return (
    <div className={classes.mainSection}>
      <HomeAppBar />
      <main>
        <div className={classes.titleForm}>
          <h2 className={classes.h2}>Select hospital type:</h2>
        </div>
        <div className={classes.userForm}>
          <div className={classes.container}>
            <Link to={'/add-hospital-offerer'} className={classes.linkButtons}>
              <h2>OFFERER</h2>
            </Link>
          </div>
          <div className={classes.container}>
            <Link to={'/add-hospital-requester'} className={classes.linkButtons}>
              <h2>REQUESTER</h2>
            </Link>
          </div>
        </div>
      </main>
    </div>
  )
}

interface IHospitalTypeProps {}

// interface IHospitalTypeState {
//   name: string
// }

export default HospitalType
