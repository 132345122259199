import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Grid, Paper } from '@material-ui/core'
import MainAppBar from '../layout/MainAppBar'
import LeftPanel from '../layout/LeftPanel'
import BedTypesForm from '../Forms/BedTypesForm'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  hospitalForm: {
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(8)
  }
}))

const BedTypes = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container sm>
        <MainAppBar />
        <Grid item sm={2}>
          <LeftPanel />
        </Grid>
        <Grid item sm>
          <Paper>
            <div>
              <main>
                <div className={classes.hospitalForm}>
                  <BedTypesForm />
                </div>
              </main>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default BedTypes
