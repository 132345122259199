import React, { useState, Fragment, useContext } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft'
import EditForm from './EditForm'
import hospitalContext from '../../context/hospitals/hospitalContext'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { ContextProps } from '../../types'

interface Props {
  match: { params: { hospitalId: string | undefined } }
  history: { push: (path: string) => void }
}
const useStyles = makeStyles((theme) => ({
  editForm: {
    justifyContent: 'center',
    display: 'flex'
  },
  removeBtn: {
    margin: theme.spacing(5),
    marginRight: theme.spacing(30),
    textAlign: 'right'
  }
}))

const EditorContainer = ({ match: { params: { hospitalId } }, history }: Props) => {
  const classes = useStyles()
  const [handleRemove, setHandleRemove] = useState(false)
  const { hospitals, deleteHospital } = useContext(hospitalContext) as ContextProps
  const currentHospital = hospitals.find(h => h._id === hospitalId)

  if (!hospitalId) {
    return (
      <>
        <Typography variant="h1">Select a hospital</Typography>
        <div className={classes.editForm}>
          <SubdirectoryArrowLeftIcon fontSize="large" />
        </div>
      </>
    )
  }

  if (!currentHospital) {
    return (<Typography variant="h1" style={{ color: 'red' }}>Hospital not found</Typography>)
  }

  const onClickDelete = () => {
    deleteHospital(currentHospital._id)
    history.push('/edit-hospitals')
    setHandleRemove(false)
  }

  return (
    <Fragment>
      <Typography variant="h1">Edit Hospital: <span>{currentHospital.fullName}</span></Typography>
      <div className={classes.editForm}><EditForm currentHospital = {currentHospital} /></div>
      <div className={classes.removeBtn}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={() => setHandleRemove(true)}
        >Remove Hospital</Button>
      </div>
      <Dialog
        open={handleRemove}
        onClose={() => setHandleRemove(false)}
      >
        <DialogTitle>Are you sure you want to delete?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span style={{ fontWeight: 600 }}>{currentHospital.fullName}</span> will be permanently removed. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleRemove(false)} color="primary">
              No, cancel
          </Button>
          <Button onClick={onClickDelete} color="primary">
              Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default EditorContainer
