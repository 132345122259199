import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ca } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns'
import Home from './components/pages/Home'
import HospitalType from './components/pages/HospitalType'
import OffererHospitals from './components/pages/OffererHospitals'
import RequesterHospitals from './components/pages/RequesterHospitals'
import BedTypes from './components/pages/BedTypes'
import EditHospitals from './components/pages/EditHospitals'
import Dashboard from './components/dashboards/Dashboard'
import HistoricalDashboard from './components/dashboards/HistoricalDashboard'
import HospitalState from './context/hospitals/hospitalState'
import Authenticator from './components/auth/Authenticator'

function App () {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ca}>
      <HospitalState>
        <Authenticator>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/add-hospital-type" component={HospitalType} />
              <Route exact path="/add-hospital-offerer" component={OffererHospitals} />
              <Route exact path="/add-hospital-requester" component={RequesterHospitals} />
              <Route exact path="/add-bed-types" component={BedTypes} />
              <Route exact path="/edit-hospitals*" component={EditHospitals} />
              <Route exact path="/dashboards" component={Dashboard} />
              <Route exact path="/dashboards/:hospitalId" component={Dashboard} />
              <Route exact path="/historical/:hospitalId" component={HistoricalDashboard} />
            </Switch>
          </Router>
        </Authenticator>
      </HospitalState>
    </MuiPickersUtilsProvider>

  )
}

export default App
