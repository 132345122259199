import Cookies from 'universal-cookie'
import axios from 'axios'
import firebase from 'firebase'
import firebaseConfig from '../../firebase/config'
const cookies = new Cookies()

const REFRESH_TIME = 15 * 60 * 1000

const refreshAccessToken = async (refreshToken: string) => {
  const headers = {
    Authorization: firebaseConfig.amalfiLoginKey
  }
  const response = await axios.post(firebaseConfig.refreshUrl, { refreshToken }, { headers })
  return response.data.token
}

const updateCredentials = async () => {
  const loginCookie = cookies.get('login')
  if (loginCookie) {
    try {
      const token = await refreshAccessToken(loginCookie.refreshToken)
      await firebase.auth().signInWithCustomToken(token)
      loginCookie.token = token
      cookies.set('login', loginCookie, { path: '/' })
    } catch (e) {
      console.error(e)
      cookies.remove('login')
      firebase.auth().signOut()
      window.location.href = '/'
    }
  }
}

export const startRefreshLoop = () => {
  updateCredentials()
  return setInterval(() => {
    updateCredentials()
  }, REFRESH_TIME)
}
