import React from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

interface Props {
    value: string
    onChange: (newValue: string) => void
}

export default function ToggleButtons ({ value, onChange }: Props) {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(_e, newVal) => onChange(newVal)}
      aria-label="text alignment"
    >
      <ToggleButton value="day">
        Dia
      </ToggleButton>
      <ToggleButton value="week" >
        Setmana
      </ToggleButton>
      <ToggleButton value="month">
        Mes
      </ToggleButton>
      <ToggleButton value="total">
        Total
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
