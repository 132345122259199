import firebase from '../firebase'
import pick from 'lodash/fp/pick'
import { Hospital, OffererHospital, RequesterHospital, HospitalTarget, HospitalSource } from '../types'

// Update the links to hospital for consistency
export function addHospitalLinks (currentHospital: Hospital, hospitals: Hospital[]) {
  if (currentHospital.type === 'OFFERER') {
    const ch = currentHospital as OffererHospital
    const chTarget = pick(['_id', 'name', 'bedTypes'], ch) as HospitalTarget
    // inserts/updates to target
    const updatableHospitals = ch.source.map(s => hospitals.find(h => h._id === s._id)).filter((h) => !!h) as RequesterHospital[]
    console.log(updatableHospitals)
    const updatedHospitals = updatableHospitals.map(h => ({ ...h, target: [...h.target.filter(f => f._id !== currentHospital._id), chTarget] }))
    const promises = Promise.all(updatedHospitals.map(h => firebase.db.collection('hospitals').doc(h._id).set(h)))

    // deletes to target
    const updatableHospitals2 = hospitals
      .filter(h => h.type === 'REQUESTER' && (h as RequesterHospital).target.find(hh => hh._id === currentHospital._id) &&
        !(currentHospital as OffererHospital).source.find(hh => h._id === hh._id)) as RequesterHospital[]
    const updatedHospitals2 = updatableHospitals2.map(h => ({ ...h, target: h.target.filter(hh => hh._id !== currentHospital._id) }))
    const promises2 = Promise.all(updatedHospitals2.map(h => firebase.db.collection('hospitals').doc(h._id).set(h)))
    return Promise.all([promises, promises2])
  } else {
    const ch = currentHospital as RequesterHospital
    const chSource = pick(['_id', 'name'], ch) as HospitalSource
    // inserts/updates to target
    const updatableHospitals = ch.target.map(s => hospitals.find(h => h._id === s._id)).filter(h => !!h) as OffererHospital[]
    const updatedHospitals = updatableHospitals.map(h => ({ ...h, source: [...h.source.filter(f => f._id !== currentHospital._id), chSource] }))
    const promises = Promise.all(updatedHospitals.map(h => firebase.db.collection('hospitals').doc(h._id).set(h)))
    // deletes to target
    const updatableHospitals2 = hospitals
      .filter(h => h.type === 'OFFERER' && (h as OffererHospital).source.find(hh => hh._id === currentHospital._id) &&
        !(currentHospital as RequesterHospital).target.find(hh => h._id === hh._id)) as OffererHospital[]
    const updatedHospitals2 = updatableHospitals2.map(h => ({ ...h, source: h.source.filter(hh => hh._id !== currentHospital._id) }))
    const promises2 = Promise.all(updatedHospitals2.map(h => firebase.db.collection('hospitals').doc(h._id).set(h)))

    return Promise.all([promises, promises2])
  }
}
