import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import HomeAppBar from '../layout/HomeAppBar'
import { HOSTPITAL_TO_NAME } from '../constants/constants'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { AuthData } from '../../types'

const useStyles = makeStyles((theme) => ({
  mainSection: {
    flex: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  userForm: {
    backgroundColor: '#2f3848',
    height: '100vh',
    width: '100%',
    minHeight: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    padding: '5rem 3rem',
    margin: '5rem',
    width: '95%',
    backgroundColor: theme.palette.background.default,
    borderRadius: '1rem',
    maxWidth: '400px'
  },
  linkButtons: {
    display: 'block',
    textDecoration: 'none',
    textAlign: 'center',
    color: '#2f3848',
    opacity: '.8'
  }
}))

const cookies = new Cookies()
const getRoles = () => {
  const loginCookie = cookies.get('login') as AuthData
  if (!loginCookie) {
    return []
  }
  try {
    return loginCookie.user.registrations[0].roles
  } catch (e) {
    return []
  }
}

const Home: React.SFC<IHomeProps> = () => {
  const classes = useStyles()
  const roles = getRoles()
  return (
    <div className={classes.mainSection}>
      <HomeAppBar />
      <main>
        <div className={classes.userForm}>
          {/* <div className={classes.container}>
            <Link to={'/dashboards'} className={classes.linkButtons}>
              <h2>Realtime Dashboards</h2>
            </Link>
          </div> */}
          {/* <div className={classes.container}>
            <Link to={'/dashboards/1590485548600-5723'} className={classes.linkButtons}>
              <h2>Dashboards VH URGÈNCIES</h2>
            </Link>
          </div>
          <div className={classes.container}>
            <Link to={'/dashboards/1590485658650-2417'} className={classes.linkButtons}>
              <h2>Dashboards VH ADMISSIONS HGE</h2>
            </Link>
          </div> */}
          {roles.includes('valldhebron') && (
            <>
              <div className={classes.container}>
                <Link to={'/historical/1590485548600-5723'} className={classes.linkButtons}>
                  <h2>Informe {HOSTPITAL_TO_NAME['1590485548600-5723']} </h2>
                </Link>
              </div>
              <div className={classes.container}>
                <Link to={'/historical/1590485658650-2417'} className={classes.linkButtons}>
                  <h2>Informe {HOSTPITAL_TO_NAME['1590485658650-2417']}</h2>
                </Link>
              </div>
            </>
          )}
          {roles.includes('santpau') && (
            <>
              <div className={classes.container}>
                <Link to={'/historical/1614587379256-1527'} className={classes.linkButtons}>
                  <h2>Informe {HOSTPITAL_TO_NAME['1614587379256-1527']} </h2>
                </Link>
              </div>
            </>
          )}

          {/* <div className={classes.container}>
            <Link to={'/add-hospital-type'} className={classes.linkButtons}>
              <h2>Add Hospital</h2>
            </Link>
          </div>
          <div className={classes.container}>
            <Link to={'/edit-hospitals'} className={classes.linkButtons}>
              <h2 >Edit Hospitals</h2>
            </Link>
          </div> */}
        </div>
      </main>
    </div>
  )
}

interface IHomeProps { }

// interface IHomeState {
//   name: string
// }

export default Home
