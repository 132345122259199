import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import CircularProgress from '@material-ui/core/CircularProgress'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/HelpOutline'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { sortBy, map, reverse } from 'lodash'

import { HOSTPITAL_TO_NAME, BED_TYPE } from '../constants/constants'

interface Props {
    title: string
    hospitalId: string
    helper?: string
    data: {
        loading: boolean
        error?: any
        value?: any
    }
}

type TableData = Array<{
    key: string
    value: Array<{
        key: string
        value: { value: number }
    }>
}>

const getValue = (value: TableData, x: string, y: string) => {
  const xx = value.find(r => r.key === x)
  if (!xx) {
    return 0
  }
  const yy = xx.value.find(c => c.key === y)
  if (!yy) {
    return 0
  }
  return yy.value.value
}

const getHospitalName = (key: string, hospitalId: string) => {
  if (key === hospitalId) {
    return 'Total'
  }
  return HOSTPITAL_TO_NAME[key] || key
}

const tablifyData = (value: TableData, hospitalId: string) => {
  const xHeadersV = reverse(sortBy(value.map(r => ({ key: r.key, name: getHospitalName(r.key, hospitalId) })), e => e.key))
  const yHeadersS: { [id: string]: string } = {}
  value.forEach(r => r.value.forEach(e => {
    yHeadersS[e.key] = BED_TYPE[e.key] || e.key
  }))
  const yHeadersV = reverse(sortBy(map(yHeadersS, (name, key) => ({ name, key })), e => e.key))
  // ineficient
  const table: number[][] = xHeadersV.map(x => yHeadersV.map(y => getValue(value, x.key, y.key)))

  const xHeaders = xHeadersV.map(e => e.name)
  const yHeaders = yHeadersV.map(e => e.name)

  return { xHeaders, yHeaders, table }
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  header: {
    fontWeight: 'bold'
  }
}))

const DataTable = ({ title, data, hospitalId, helper }: Props) => {
  const classes = useStyles()
  let content = (
    <div><CircularProgress /></div>
  )
  if (!data.loading && !data.error) {
    const { xHeaders, yHeaders, table } = tablifyData(data.value, hospitalId)
    if (data.value.length === 0) {
      content = (<span>No s&apos;han efectuat derivacions d&apos;aquest tipus en aquest període.</span>)
    } else {
      content = <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow >
              <TableCell></TableCell>
              {yHeaders.map(header => (
                <TableCell className={classes.header} key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              xHeaders.map((row, i) => (
                <TableRow key={row}>
                  <TableCell className={classes.header}>
                    {row}
                  </TableCell>
                  {
                    table[i].map(e => (
                      <TableCell key={e}>{e}</TableCell>
                    ))
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    }
  } else if (data.error) {
    content = (<div>{JSON.stringify(data.error)}</div>)
  }

  return (

    <Grid item className={classes.paper} lg={6} md={12} sm={12} xs={12}>
      <Card>
        <CardContent>

          <Typography variant="h6" >
            {title} &nbsp;
            {helper && (
              <Tooltip title={helper}>
                <IconButton><HelpIcon /></IconButton>
              </Tooltip>
            )}
          </Typography>

          {content}
        </CardContent>
      </Card>
    </Grid>
  )
}

export default DataTable
