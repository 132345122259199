import React, { useState, useContext } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Add, Delete, Edit } from '@material-ui/icons'
import {
  Input,
  InputLabel,
  Button,
  Grid,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Select,
  Checkbox,
  Chip,
  ListItemSecondaryAction,
  FormControl,
  IconButton,
  Typography,
  useTheme
} from '@material-ui/core'
import pick from 'lodash/fp/pick'
import hospitalContext from '../../context/hospitals/hospitalContext'
import { OffererHospital, RequesterHospital, ContextProps, HospitalSource } from '../../types'
import firebase from '../../firebase'
import { addHospitalLinks } from '../../lib/utils'

interface OffererHospitalForm extends Omit<OffererHospital, 'source'> {
  source: string[]
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '95%',
    maxWidth: theme.spacing(90)
  },
  formField: {
    display: 'flex',
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2)
  },
  userField: {
    display: 'flex',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  label: {
    flexBasis: theme.spacing(20),
    marginTop: theme.spacing(2)
  },
  legend: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    fontWeight: 'bold'
  },
  btn: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2.5),
    fontSize: theme.typography.fontSize,
    borderRadius: theme.spacing(1),
    fontWeight: 'bold'
  },
  list: {
    overflow: 'auto',
    width: '100%',
    maxHeight: 200,
    maxWidth: 800
  }
}))

const INITIAL_STATE: OffererHospitalForm = {
  _id: '',
  name: '',
  fullName: '',
  type: 'OFFERER',
  bedTypes: [],
  users: [],
  source: []
}

const CreateOffererForm = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [newUser, setNewUser] = useState('')
  const [handleSuccess, setHandleSuccess] = useState(false)
  const [userExist, setUserExist] = useState(false)
  const [fullNameError, setFullNameError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [bedTypesError, setBedTypesError] = useState(false)
  const [formState, setFormState] = useState(INITIAL_STATE)
  const { name, fullName, type, bedTypes, users, source } = formState

  const handleUserChange = (e: { target: { value: React.SetStateAction<string> } }) => setNewUser(e.target.value)
  const handleAddUser = () => {
    if (newUser.length > 1) {
      if (users.includes(newUser)) {
        setUserExist(true)
      } else {
        setFormState({ ...formState, users: [...users, newUser] })
        setNewUser('')
      }
    }
  }
  const deleteUser = (email: string) => {
    setFormState({ ...formState, users: users.filter(e => e !== email) })
  }
  const editUser = (email: string) => {
    setNewUser(email)
    setFormState({ ...formState, users: users.filter(e => e !== email) })
  }

  const { hospitals, globalBedTypes } = useContext(hospitalContext) as ContextProps
  const requesterHospitals = hospitals.filter(h => h.type === 'REQUESTER') as RequesterHospital[]
  console.log(bedTypes)

  const validateForm = () => {
    setFullNameError(fullName.length < 2 || (hospitals.find(h => h.fullName === fullName) !== undefined))
    setNameError(name.length < 2 || (hospitals.find(h => h.name === name) !== undefined))
    setBedTypesError(Object.keys(bedTypes).length === 0)
    if ((!(name.length < 2) && (hospitals.find(h => h.name === name) === undefined)) &&
    (!(fullName.length < 2) && (hospitals.find(h => h.fullName === fullName) === undefined)) &&
    !(Object.keys(bedTypes).length === 0)) {
      createHospital()
      setHandleSuccess(true)
      setFormState(INITIAL_STATE)
    }
  }

  async function createHospital () {
    const hospital: OffererHospital = {
      _id: `${Date.now()}-${String(Math.random()).slice(2, 6)}`,
      name,
      fullName,
      type,
      source: source.map(h => pick(['_id', 'name'], requesterHospitals.find(r => r._id === h))) as HospitalSource[],
      bedTypes,
      users
    }
    // Insert the hospital object in the data base
    await firebase.db.collection('hospitals').doc(hospital._id).set(hospital)
    await addHospitalLinks(hospital, hospitals)
  }

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h1">Add Offerer Hospital</Typography>
        <form
          onSubmit={e => {
            e.preventDefault()
            validateForm()
          }}
          noValidate
        >
          <fieldset>
            <legend className={classes.legend}>Hospital data</legend>
            <div className={classes.formField}>
              <label className={classes.label} htmlFor="fullName">Full Name</label>
              <TextField
                required
                label="Hospital full name"
                variant="outlined"
                fullWidth
                error={fullNameError && (fullName.length < 2 || hospitals.find(h => h.fullName === fullName) !== undefined)}
                helperText={(fullNameError && fullName.length < 2) ? 'Empty field!'
                  : (((fullNameError && hospitals.find(h => h.fullName === fullName) !== undefined))
                    ? `"${fullName}" hospital already exists` : '')}
                type="text"
                id="fullName"
                name="fullName"
                value={fullName}
                onChange={e => setFormState({ ...formState, fullName: e.target.value })}
              />
            </div>
            <div className={classes.formField}>
              <label className={classes.label} htmlFor="name">Short Name</label>
              <TextField
                required
                label="Hospital acronym or short name"
                variant="outlined"
                fullWidth
                error={nameError && (name.length < 2 || (hospitals.find(h => h.name === name) !== undefined))}
                helperText={ (nameError && name.length < 2) ? 'Empty field!'
                  : (((nameError && hospitals.find(h => h.name === name) !== undefined))
                    ? `"${name}" hospital already exists` : '')}
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={e => setFormState({ ...formState, name: e.target.value })}
              />
            </div>
            <div className={classes.formField}>
              <label className={classes.label} htmlFor="bedTypes">Bed Types</label>
              <FormControl fullWidth variant='outlined'>
                <InputLabel style={ (bedTypesError && Object.keys(bedTypes).length === 0) ? { color: theme.palette.error.main } : { }}>Select bed type *</InputLabel>
                <Select
                  multiple
                  error={bedTypesError && Object.keys(bedTypes).length === 0}
                  placeholder="Select Bed Type"
                  id="bedTypes"
                  label="Select Bed Type"
                  name="bedTypes"
                  value={bedTypes}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                  onChange={e => setFormState({ ...formState, bedTypes: e.target.value as string[] })}
                >
                  {globalBedTypes.map((n) => (
                    <MenuItem key={n.name} value={n.name}>
                      <Checkbox checked={bedTypes.indexOf(n.name) > -1} />
                      <ListItemText primary={n.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.userField}>
              <label className={classes.label} style={{ marginTop: '1.5rem' }} htmlFor="users">User</label>
              <Grid item container spacing={8} style={{ alignItems: 'flex-end' }}>
                <Grid item style={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    label='User email'
                    name="users"
                    onChange={handleUserChange}
                    value={newUser}
                  />
                </Grid>
                <Grid item>
                  <Button
                    style={{ marginRight: '1rem' }}
                    size="small"
                    variant="outlined"
                    onClick={handleAddUser}
                  >
                    <Add />
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div className={classes.userField}>
              <label className={classes.label}></label>
              <List dense={true} className={classes.list}>
                {!users ? null : users.map(email => (
                  <ListItem key={email}>
                    <ListItemText>
                      {email}
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="edit">
                        <Edit fontSize="small" onClick={() => editUser(email)}/>
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteUser(email)}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          </fieldset>
          <div style={{ paddingTop: theme.spacing(2) }}>
            <fieldset>
              <legend className={classes.legend}>Hospital Links</legend>
              <div className={classes.formField}>
                <label className={classes.label} htmlFor="users">Target hospital</label>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel>Select target hospital </InputLabel>
                  <Select
                    multiple
                    name="source"
                    value={source}
                    onChange={(e) => {
                      setFormState({ ...formState, source: e.target.value as string[] })
                    }}
                    input={<Input />}
                    renderValue={(selected) => (
                      <div>
                        {(selected as string[]).map((h) => (
                          <Chip key={h} label={(requesterHospitals.find(hh => h === hh._id) as RequesterHospital).name} />
                        ))}
                      </div>
                    )}
                  >
                    {requesterHospitals.map(hosp => (
                      <MenuItem key={hosp._id} value={hosp._id}>
                        <Checkbox
                          checked={!!source.find(h => h === hosp._id)}
                        />
                        <ListItemText primary={hosp.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </fieldset>
          </div>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth={true}
            className={classes.btn}
          >Create Hospital
          </Button>
        </form>
        <Dialog
          open={handleSuccess}
          onClose={() => setHandleSuccess(false)}
        >
          <DialogTitle>Hospital created</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The new hospital has been successfully added
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHandleSuccess(false)} color="primary">
              ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={userExist}
          onClose={() => setUserExist(false)}
        >
          <DialogTitle>This user already exists!</DialogTitle>
          <DialogActions>
            <Button onClick={() => setUserExist(false)} color="primary">
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}

export default CreateOffererForm
