export const HOSTPITAL_TO_NAME: {[key:string]: string} = {
    '1590485548600-5723': 'VH URGÈNCIES',
    '1590485658650-2417': 'VH ADMISSIONS HGE',
    '1590486820138-3259': 'PERE VIRGILI',
    '1590486408235-5677': 'H St RAFAEL',
    '1590486858010-4598': 'ISABEL ROIG',
    '1590486894170-2527': 'COTXERES',
    '1614587379256-1527': 'Sant Pau',
    '1614587501528-8416': 'Mutuam Güell',
    '1614587618397-6657': 'Evangèlic',
    '1614587653108-9620': 'Hestia Palau'
}

export const BED_TYPE: {[key: string]: string} = {
    'acute': 'Aguts',
    'subacute': 'Subaguts',
    'paliative': 'Paliatius',
    'convalescent': 'Convalescent'
}

export const CURRENT_OFFERS_INDEX: string = 'mint-offers-current'
