import {
  GET_HOSPITALS,
  DELETE_HOSPITAL,
  AUTH_USER,
  LOGOUT,
  Hospital,
  ContextAttributes,
  PartialHospital,
  AuthData,
  GET_BED_TYPES,
  BedType
} from '../../types'

export interface ReducerAction {
  type: String
  payload?: string | Hospital | Hospital[] | PartialHospital | BedType[]// TODO separate this
  authData?: AuthData
}

const reducer = (state: ContextAttributes, action: ReducerAction) => {
  switch (action.type) {
    case GET_HOSPITALS:
      return {
        ...state,
        hospitals: action.payload as Hospital[]
      }
    case GET_BED_TYPES:
      return {
        ...state,
        globalBedTypes: action.payload as BedType[]
      }
    case DELETE_HOSPITAL:
      return {
        ...state,
        hospitals: state.hospitals.filter((hospital: Hospital) => hospital._id !== action.payload),
        hospital: null
      }
    case AUTH_USER:
      return {
        ...state,
        authenticated: true
      }
    case LOGOUT:
      return {
        ...state,
        authenticated: false
      }
    default:
      return state
  }
}

export default reducer
