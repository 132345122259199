import React, { useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import DashboardsAppBar from '../layout/DashboardsAppBar'

interface User {
  email: string,
  password: string
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    paddingTop: '56.25%'
  },
  iframe: {
    position: 'absolute',
    top: '78.88px',
    bottom: 0,
    width: '100%',
    height: '100%'
  }
})

interface IDashboardProps {
  match: any
 }

function useInterval (callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>()
  useEffect(() => { savedCallback.current = callback }, [callback])
  useEffect(() => {
    function tick () { savedCallback.current && savedCallback.current() }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

const kibanaDashboardUrl = '/kibana/s/admint/app/kibana'
const hashAll = '#/dashboard/ab3468e0-77eb-11ea-bd64-e7cc0308b7fa?_g=(refreshInterval%3A(pause%3A!f%2Cvalue%3A15000)%2Ctime%3A(from%3Anow-30d%2Cto%3Anow))&_a=(fullScreenMode:!t)'
const hashFiltered = (hospitalId: string) => {
  return `#/dashboard/ab3468e0-77eb-11ea-bd64-e7cc0308b7fa?_g=(filters:!(),refreshInterval%3A(pause%3A!f%2Cvalue%3A15000)%2Ctime%3A(from%3Anow-30d%2Cto%3Anow))&_a=(description:'',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:c50b9f60-77e9-11ea-bd64-e7cc0308b7fa,key:involvedHospitals.keyword,negate:!f,params:(query:'${hospitalId}'),type:phrase),query:(match_phrase:(involvedHospitals.keyword:'${hospitalId}')))),fullScreenMode:!t,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!t,title:'%5BMint%5D%20Back-office%20Active',viewMode:view)`
}

const Dashboard = (props: IDashboardProps) => {
  const params = (props as any).match.params
  let hash = hashAll
  if (params.hospitalId) {
    hash = hashFiltered(params.hospitalId)
  }
  const classes = useStyles()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  useInterval(() => {
    if (iframeRef !== null && iframeRef.current !== null && iframeRef.current.contentWindow !== null && iframeRef.current.contentDocument !== null) {
      const height = iframeRef.current.contentDocument.documentElement.scrollHeight
      if (height > (parseInt(iframeRef.current.style.height) || 0)) {
        iframeRef.current.style.height = `${height + 10}px`
      }
    }
  }, 1000)

  return (
    <>
      <div>
        <DashboardsAppBar />
      </div>
      <div className={classes.container}>
        <iframe
          title="dashboard"
          ref={iframeRef}
          className={classes.iframe}
          src={`${kibanaDashboardUrl}${hash}`}
          frameBorder="0"
        />
      </div>
    </>
  )
}

export default Dashboard
